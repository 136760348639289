<template>
  <KpiDivisiUploadForm mode="Upload File" module="Indikator Kinerja"> </KpiDivisiUploadForm>
</template>

<script>
import KpiDivisiUploadForm from './form-upload-kpi';

const KpiDivisiUpload = {
  name: 'KpiDivisiUpload',
  components: { KpiDivisiUploadForm },
};

export default KpiDivisiUpload;
</script>
